// html {
//     height: 100%;
// }
// body {
//     padding: 0;
//     margin: 0;
//     height: 100%;
//     overflow: hidden;
// }
    
.canvas {
    // margin: 0 auto;
    // background: url("https://unsplash.it/2048/2048?random") no-repeat center;
    // background-size: cover;
    // background-color: whitesmoke
    // background-size: auto 100%;
    // display: block;
    // display: flex;
    // overflow: auto;
    flex: 1 0 auto;
}

.canvas-letter {
    padding: 5px;
    display: inline-block;
    text-align: center;
    color: #fff;
    font-weight: 900;
    -webkit-text-stroke-color: #000;
    word-wrap: break-word;
}

.shake{
    animation: shake 1s infinite;
}
@keyframes shake {
  0%  { transform: translate(2px, 1px)   rotate(0deg);  }
  10% { transform: translate(-1px, -2px) rotate(-2deg); }
  20% { transform: translate(-3px, 0px)  rotate(3deg);  }
  30% { transform: translate(0px, 2px)   rotate(0deg);  }
  40% { transform: translate(1px, -1px)  rotate(1deg);  }
  50% { transform: translate(-1px, 2px)  rotate(-1deg); }
  60% { transform: translate(-3px, 1px)  rotate(0deg);  }
  70% { transform: translate(2px, 1px)   rotate(-2deg); }
  80% { transform: translate(-1px, -1px) rotate(4deg);  }
  90% { transform: translate(2px, 2px)   rotate(0deg);  }
  100%{ transform: translate(1px, -2px)  rotate(-1deg); }
}

.searchlight {
    position: absolute;
    z-index: 100;
    height: 300px;
    width: 300px;
    border-width: 100vh 100vw;
    border-style: solid;
    border-color: #000;
    top: -100vh;
    left: -100vw;
    cursor: none;
    background: #000;
    -moz-box-sizing: content-box;
    -webkit-box-sizing: content-box;
    -ms-box-sizing: content-box;
    box-sizing: content-box;
}
.searchlight.on {
    background: -moz-radial-gradient(center, ellipse cover,  rgba(0,0,0,0) 0%, rgba(0,0,0,0) 50%, rgba(0,0,0,1) 60%, rgba(0,0,0,1) 100%); /* FF3.6+ */
    background: -webkit-radial-gradient(center, ellipse cover,  rgba(0,0,0,0) 0%,rgba(0,0,0,0) 50%,rgba(0,0,0,1) 60%,rgba(0,0,0,1) 100%); /* Chrome10+,Safari5.1+ */
    background: -o-radial-gradient(center, ellipse cover,  rgba(0,0,0,0) 0%,rgba(0,0,0,0) 50%,rgba(0,0,0,1) 60%,rgba(0,0,0,1) 100%); /* Opera 12+ */
    background: -ms-radial-gradient(center, ellipse cover,  rgba(0,0,0,0) 0%,rgba(0,0,0,0) 50%,rgba(0,0,0,1) 60%,rgba(0,0,0,1) 100%); /* IE10+ */
    background: radial-gradient(ellipse at center,  rgba(0,0,0,0) 0%,rgba(0,0,0,0) 50%,rgba(0,0,0,1) 60%,rgba(0,0,0,1) 100%); /* W3C */
}

.img-text-wrapper {
    position: absolute;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100px !important;
    text-align: center;
    padding-left: 100px;
    padding-right: 100px;
}